// src/pages/AgendaPage.js
import React, { useState, useEffect, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import api from 'services/api';
import { AccessibilityContext } from 'contexts/AccessibilityContext';

// Composants
import EventFilter from 'components/calendar/EventFilter';
import EventModal from 'components/calendar/EventModal';
import RenderEventContent from 'components/calendar/RenderEventContent';
import ListView from 'components/calendar/ListView';
import GridView from 'components/calendar/GridView';
import BannerWithSocialIcons from 'components/common/BannerWithSocialIcons';
import Breadcrumbs from 'components/common/Breadcrumbs';
import NearestEventList from 'components/events/NearestEventList';
import MapCta from 'components/common/MapCta';
import LiensPratiques from 'components/common/LiensPratiques';
import Spinner from 'components/common/Spinner';

// Styles
import styles from './AgendaPage.module.css';
import archiveStyle from 'styles/modules/Archives.module.css';

// Autres
import { useSubSite } from 'contexts/SubSiteContext';
import useDesignSettingsData from 'hooks/useDesignSettingsData';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CalendarOutline, Grid, List } from 'react-ionicons';
import expandEventsForCalendar from 'utils/expandEventsForCalendar';
import frLocale from '@fullcalendar/core/locales/fr';
import { set } from 'date-fns';

// Hook personnalisé de debounce
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

const AgendaPage = () => {
  // ----- États des filtres -----
  const [nameFilter, setNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [specialFilter, setSpecialFilter] = useState(false);
  const debouncedNameFilter = useDebounce(nameFilter, 300);

  // ----- Événements -----
  const [events, setEvents] = useState([]);
  const [gridEvents, setGridEvents] = useState([]);

  // ----- Catégories -----
  const [categories, setCategories] = useState([]);

  // ----- Chargement et erreurs -----
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // ----- Vue active (onglet) -----
  const [activeTab, setActiveTab] = useState('calendar');

  // ----- Modal -----
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // ----- Hooks / context -----
  const { isSubSite, subSiteSlug } = useSubSite();
  const { getAccessibilityVariant } = useContext(AccessibilityContext);
  const { designSettings, settingsLoading, error: designError } = useDesignSettingsData();

  // ----- useEffect : Charger les catégories depuis l'endpoint -----
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/events/calendar/categories');

        setCategories(response.data);
      } catch (err) {
        console.error('Erreur lors de la récupération des catégories:', err);
      }
    };
    fetchCategories();
  }, []);

  // ----- useEffect : Charger les événements filtrés -----
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setError(null);
      try {
        const baseUrl = isSubSite
          ? `/subsites/${subSiteSlug}/events/calendar`
          : '/events/calendar';

        const GridBaseUrl = isSubSite
          ? `/subsites/${subSiteSlug}/events/calendar/grid`
          : '/events/calendar/grid';

        const params = {
          nameFilter: debouncedNameFilter.length >= 3 ? debouncedNameFilter : undefined,
          categoryFilter: categoryFilter || undefined,
          specialFilter: specialFilter ? 'true' : undefined,
        };

        const response = await api.get(baseUrl, { params });
        const gridResponse = await api.get(GridBaseUrl, { params });

        setGridEvents(gridResponse.data);
        const expanded = expandEventsForCalendar(response.data);
        setEvents(expanded);
      } catch (err) {
        console.error('Erreur fetchEvents:', err);
        setError('Une erreur est survenue lors du chargement des événements.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [debouncedNameFilter, categoryFilter, specialFilter, isSubSite, subSiteSlug]);

  // ----- Reset des filtres -----
  const resetFilters = () => {
    setNameFilter('');
    setCategoryFilter('');
    setSpecialFilter(false);
  };

  // ----- handleEventClick -----
  const handleEventClick = (eventData) => {
    const event = eventData.event || eventData;
    const title = event.title || 'Titre non spécifié';
    const start = event.startStr || event.start || null;
    const end = event.endStr || event.end || null;
    const location = event.location || event.extendedProps?.location || null;
    const description =
      event.description || event.extendedProps?.description || 'Pas de description';
    const categoriesData = event.categories || event.extendedProps?.categories || [];
    const thumbnail = event.thumbnail || event.extendedProps?.thumbnail || null;
    const slug = event.slug || event.extendedProps?.slug || event.id;

    setSelectedEvent({
      title,
      start,
      end,
      location,
      content: [{ content: description }],
      categories: categoriesData,
      thumbnail,
      slug,
      id: event.id,
    });
    setIsModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Arras | Agenda</title>
        <meta
          name="title"
          content="Arras | Agenda"
        />
        <meta
          name="description"
          content="Sports, culture, participation citoyenne, … Ça bouge à Arras !"
        />
        <meta
          name="og:title"
          content="Arras | Agenda"
        />
        <meta
          name="og:description"
          content="Sports, culture, participation citoyenne, … Ça bouge à Arras !"
        />
        <link rel="canonical" href="https://www.arras.fr/agenda" />
      </Helmet>

      {settingsLoading ? (
        <Spinner />
      ) : (
        <div
          className={`${styles.agendaPage} ${archiveStyle.pageWrapper} ${styles[getAccessibilityVariant()]}`}
        >
          {/* Bannière */}
          <BannerWithSocialIcons
            imageUrl={`${designSettings.defaultEventBanner}`}
            altText="Bannière de la page agenda"
          />

          {/* Header / Filtres */}
          <section className={`global-section ${archiveStyle.headerSection}`}>
            <div className={`global-container ${archiveStyle.header}`}>
              <div className={archiveStyle.headingBlock}>
                <h1>Agenda</h1>
                <Breadcrumbs pageType="isCalendar" title="Agenda" />
              </div>
              <EventFilter
                nameFilter={nameFilter}
                setNameFilter={setNameFilter}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
                resetFilters={resetFilters}
                specialFilter={specialFilter}
                setSpecialFilter={setSpecialFilter}
                categories={categories}
              />
            </div>
          </section>

          {/* Contenu principal */}
          <div className={`page-content ${archiveStyle.pageContent}`}>
            {loading ? (
              <Spinner />
            ) : error || designError ? (
              <div>{error || designError}</div>
            ) : (
              <section className={`global-section ${archiveStyle.gridSection}`}>
                <div className="global-container">
                  <div className={archiveStyle.toggles}>
                    <button
                      className={`${archiveStyle.toggle} ${
                        activeTab === 'calendar' ? archiveStyle.active : ''
                      }`}
                      onClick={() => setActiveTab('calendar')}
                      aria-label="Bascule vue en calendrier"
                      title="Bascule vue en calendrier"
                    >
                      <CalendarOutline color="currentColor" />
                      Vue en calendrier
                    </button>

                    <button
                      className={`${archiveStyle.toggle} ${
                        activeTab === 'grid' ? archiveStyle.active : ''
                      }`}
                      onClick={() => setActiveTab('grid')}
                      aria-label="Bascule vue en grille"
                      title="Bascule vue en grille"
                    >
                      <Grid color="currentColor" />
                      Vue en grille
                    </button>

                    <button
                      className={`${archiveStyle.toggle} ${
                        activeTab === 'list' ? archiveStyle.active : ''
                      }`}
                      onClick={() => setActiveTab('list')}
                      aria-label="Bascule vue en liste"
                      title="Bascule vue en liste"
                    >
                      <List color="currentColor" />
                      Vue en liste
                    </button>
                  </div>

                  {/* Dropdown de vue pour mobile */}
                  <div className={archiveStyle.dropdownContainer}>
                    <select
                      className={archiveStyle.viewSelect}
                      value={activeTab}
                      onChange={(e) => setActiveTab(e.target.value)}
                      aria-label="Sélectionnez un type de vue"
                      title="Sélectionnez un type de vue"
                    >
                      <option value="calendar">Vue en calendrier</option>
                      <option value="grid">Vue en grille</option>
                      <option value="list">Vue en liste</option>
                    </select>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </div>
                </div>

                {activeTab === 'calendar' && (
                  <div className="global-container fcContainer">
                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      slotMinTime= "07:00"
                      locale={frLocale}
                      events={events}
                      moreLinkClick="popover" // Option par défaut pour ouvrir le popover
                      height="auto"

                      eventContent={RenderEventContent}
                      eventClick={handleEventClick}
                      headerToolbar={{
                        left: 'title prev,next',
                        center: 'today',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay',
                      }}
                      buttonText={{
                        today: "Aujourd'hui",
                        month: 'Mois',
                        week: 'Semaine',
                        day: 'Jour',
                      }}
                      dayMaxEventRows={30}
                    />
                    <div className="global-container">
                    <GridView
                    events={gridEvents}
                    onEventClick={handleEventClick}
                    accessibilityVariant={getAccessibilityVariant()}
                  />                    </div>
                  </div>
                )}

                {activeTab === 'grid' && (
                  <GridView
                    events={gridEvents}
                    onEventClick={handleEventClick}
                    accessibilityVariant={getAccessibilityVariant()}
                  />
                )}

                {activeTab === 'list' && (
                  <ListView events={gridEvents} onEventClick={handleEventClick} />
                )}

                {selectedEvent && (
                  <EventModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    event={selectedEvent}
                    accessibilityVariant={getAccessibilityVariant()}
                  />
                )}
              </section>
            )}

            <MapCta />
            <LiensPratiques />
          </div>
        </div>
      )}
    </>
  );
};

export default AgendaPage;
